


























import { Component, Prop, Vue } from 'vue-property-decorator'
import QrcodeVue from 'qrcode.vue'
import { BButton } from 'bootstrap-vue'
import { RouteNames } from '@/router'
import { round } from 'lodash-es'

@Component({
  components: { QrcodeVue, BButton },
  filters: {
    round
  }
})
export default class ShowPaymentReceipt extends Vue {
  readonly RouteNames = RouteNames

  @Prop({ type: String, required: true })
  receiptUrl!:string
}
